const cookies = document.cookie;
const isHome = document.querySelector('.page-home-data');
const loader = document.getElementById('loader-intro');

if(getCookie('load') || !isHome) {
  //console.log('remove');
  loader.remove();
}

function load() {
  console.log("cargando...");
  if(getCookie('load')) {
    //console.log('load cargado anteriormente');
    document.querySelector(".mx-auto").style.display = "block";
  } else {
    if (isHome) {
      //console.log('Estamos en home, carga el loader');
      
      document.getElementsByTagName('body')[0].appendChild(loader);
      
    } else {
      //console.log('No estamos en home, no cargues el loader');
      document.querySelector(".mx-auto").style.display = "block";
    }
  }

  return new Promise(function (resolve, reject) {
    window.onload = resolve;
  });
}

load().then(function () {
  if(!getCookie('load')) {
    loader.classList.add('tracking-out-contract-bck-bottom');
    //console.log('se cargo la clase de la animacion de salida del loader');
    if (isHome) {
      setTimeout(() => {
        //Cambia display de wrapper
        document.querySelector(".mx-auto").style.display = "block";
        //Elimina el loader para que no quede tapando elementos en la pagina
        loader.remove();
        //localStorage.setItem('load', 'load');
        document.cookie = "load=load;path=/";
      }, 4800 /*este es el tiempo de la animacion*/);
    }else{
      loader.remove();
    }
  }else{
    console.log('load off')
  }
});

const btnMenuMobile = document.getElementById('btn-menu-mobile');
const menuMobile = document.getElementById('menu-mobile');

btnMenuMobile.addEventListener("click", () => {
  console.log('active')
  if (!menuMobile.classList.contains('active')) {
    menuMobile.classList.add('active');
    return;
  }

  menuMobile.classList.remove('active');
});

window.addEventListener('click', function (e) {
  setTimeout(() => {
    if (
      !document.getElementById('menu-mobile').contains(e.target)
      &&
      menuMobile.classList.contains('active')
      &&
      !document.getElementById('btn-menu-mobile').contains(e.target)
    ) {
      menuMobile.classList.remove('active');
    }
  }, 100);
})


function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
